import { sprintf } from '../../../node_modules/sprintf-js';
import { Constants } from '@app/helpers/Constants.ts';
import { isNumeric } from 'rxjs/util/isNumeric';

export function numberWithCommas(x) {
  if (x == null) {
    x = 0;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function calcPriceByRate(value, exchangeRate) {
  return (value * exchangeRate).toFixed(2);
}

export function formatCurrency(value, currencySign) {
  if (value == null) {
    value = 0;
  }
  if (isNumeric(value)) {
    var str = sprintf("%.2f", value);
  } else {
    var str = "";
  }
  return numberWithCommas(str) + " " + currencySign;
}
//if they have problem with difference rounding in excel and view( 0.345 , 3,345 , 4,345 with %.2f)
// function roundToTwo(num) {
//   return +(Math.round(num + "e+2") + "e-2");
//   }
export function formatWithSuffixAndPrecision(value, suffix, valuePrecision) {
  if (valuePrecision === undefined) {
    valuePrecision = 0;
  }
  if (suffix === undefined) {
    suffix = '';
  }

  var formatter = '%.' + valuePrecision + 'f';
  if (isNumeric(value)) {
    var str = sprintf(formatter, value);
  } else {
    var str = "";
  }
  return `${numberWithCommas(str)} ${suffix}`;//two strings separate with space
}

export function getColIndex22ToLength(field) {
  return Number((field).substring(22, field.length - 2));//values[4] - return 4
}

export function getModifyState(modified, error) {
  let state = Constants.NO_MODIFIED_CELL;
  if (modified) {
    state = Constants.MODIFIED_CELL;
  }
  if (error) {
    state = Constants.ERROR_MODIFIED_CELL;
  }
  return state;
}

export function getNameOfColumn(data, ids) {
  const row = data[0];
  let name = row.children[1].values[ids] + /*market*/ ',' + row.children[2].values[ids] /*make*/
    + ',' + row.children[3].values[ids] /*model*/ + ',' + row.children[14].values[ids] /*fuel*/
    + ',' + row.children[4].values[ids] /*name*/;

  if (row.children[5].values[ids] !== undefined && row.children[5].values[ids] !== null && row.children[5].values[ids] !== '') {
    name += ' (' + row.children[5].values[ids] /*trimLevel*/ + ')';
  }
  return name;
}

export function getVehicleFromSheet(sheet, vehicle, idCol) {
  let jp = require('jsonpath');

  let path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'market\')]' + '.values[\'' + idCol + '\']';
  vehicle.market = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'name\')]' + '.values[\'' + idCol + '\']';
  vehicle.car = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'make\')]' + '.values[\'' + idCol + '\']';
  vehicle.make = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'model\')]' + '.values[\'' + idCol + '\']';
  vehicle.model = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'saleStart\')]' + '.values[\'' + idCol + '\']';
  vehicle.saleStart = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'saleEnd\')]' + '.values[\'' + idCol + '\']';
  vehicle.saleEnd = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'drive\')]' + '.values[\'' + idCol + '\']';
  vehicle.drive = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'transmission\')]' + '.values[\'' + idCol + '\']';
  vehicle.transmission = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'speeds\')]' + '.values[\'' + idCol + '\']';
  vehicle.speeds = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'fuel\')]' + '.values[\'' + idCol + '\']';
  vehicle.fuel = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'engineSize\')]' + '.values[\'' + idCol + '\']';
  vehicle.engineSize = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'cylinders\')]' + '.values[\'' + idCol + '\']';
  vehicle.cylinders = jp.query(sheet.dataTCO, path)[0];
  path = '$[?(@.field==\'carSpecs\')].children[?(@.field==\'horsePower\')]' + '.values[\'' + idCol + '\']';
  vehicle.horsePower = jp.query(sheet.dataTCO, path)[0];
  // console.log(vehicle, "vehicle", idCol, sheet);
  return vehicle;
}

export function setSheetScenario(sheet, scenario) {
  let jp = require('jsonpath');
  sheet.columnIds.forEach(idCol => {
    let path = '$[?(@.field==\'scenario\')].children[?(@.field==\'distance\')]' + '.values[\'' + idCol + '\']';
    jp.value(sheet.dataTCO, path, scenario.distance);

    path = '$[?(@.field==\'scenario\')].children[?(@.field==\'duration\')]' + '.values[\'' + idCol + '\']';
    jp.value(sheet.dataTCO, path, scenario.duration);
  });
}

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// call this to Disable
export function disableScroll() {
  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
      get: function () { supportsPassive = true; }
    }));
  } catch (e) { }

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enableScroll() {
  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
      get: function () { supportsPassive = true; }
    }));
  } catch (e) { }

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

export function reviver(key, value) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

export function replacer(key, value) {//JSON.stringify cannot deal with Set and Map
  const originalObject = this[key];
  if (originalObject instanceof Map) {
    let obj = Array.from(originalObject).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
    return obj;
  } else if (originalObject instanceof Set) {
    return [...originalObject];
  } else {
    return value;
  }
}

//Because this.columnsWidths is not array but map
export function switchColWidth(fromIndex, toIndex, columnsWidths) {
  let toOld;
  let fromOld;
  const startToIndex = toIndex;
  const startFromIndex = fromIndex;
  if (toIndex < fromIndex) {
    toOld = columnsWidths.get(toIndex);
    fromOld = columnsWidths.get(fromIndex);
    for (; toIndex <= startFromIndex; toIndex++) {
      columnsWidths.set(toIndex, fromOld);
      fromOld = toOld;
      if (toIndex != columnsWidths.size - 1) {
        toOld = columnsWidths.get(toIndex + 1);
      }
    }
  } else {
    toOld = columnsWidths.get(toIndex);
    fromOld = columnsWidths.get(fromIndex);
    for (; toIndex >= startFromIndex; toIndex--) {
      columnsWidths.set(toIndex, fromOld);
      fromOld = toOld;
      if (toIndex != 0) {
        toOld = columnsWidths.get(toIndex - 1);
      }
    }
  }
}

export function cleanArray(array) {
  while (array.length > 0) {
    array.pop();
  }
}

export function roundValue(value, field) {
  if (value !== null && value !== undefined && Constants.CURRENCY_PLACE_HOLDERS.includes(field)) {
    return Number((Math.round(value * 100000) / 100000).toFixed(2));
  } else if (value !== null && value !== undefined && Constants.PERCENTAGE_PLACE_HOLDERS.includes(field)) {
    return Number((Math.round(value * 100000) / 100000).toFixed(5));
  }
  return null;
}
