import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Constants } from '@app/helpers/Constants';

@Component({
  selector: 'app-pr-dispatch',
  templateUrl: './pr-dispatch.component.html',
  styleUrls: ['./pr-dispatch.component.scss']
})
export class PrDispatchComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;
  renderAs: string;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    var field = params.data.field;
    this.renderAs = Constants.CURRENCY_PLACE_HOLDERS.includes(field) ? "currency" : "percentage";
    // console.log(params, "paramdispatch")
  }

  refresh(params: any): boolean {
    // TODO: properly implement this
    return false;
  }
}
