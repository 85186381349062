import { MixPanelService } from './../../services/mix-panel.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { EquipmentService } from '@app/services/equipment.service';
import { SheetService } from '@app/services/sheet.service';
import { getCurrencySignByID, getDistanceUnitByID } from '@app/helpers/RemapUnitsUtils.ts';
import { UserPreferencesService } from '@app/services/user-preferences.service';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { calcPriceByRate } from '@app/helpers/utils.js';
import * as lodash from 'lodash'; //copy array

import { environment } from '@environments/environment';
import { SheetComponent } from '../sheet/sheet.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-dialog-excel-export',
    templateUrl: './dialog-excel-export.component.html',
    styleUrls: ['./dialog-excel-export.component.scss']
})
export class DialogExcelExportComponent {

    parent;
    sheetComponent: SheetComponent;
    currencyUnit;
    distanceUnit;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService, private http: HttpClient,
      private equipmentService: EquipmentService, private sheetService: SheetService, private mixPanelServ: MixPanelService,
      private userPreferencesService: UserPreferencesService, private messagingService: HandleUiMessagesService) {
        this.parent = data.parent;
        this.sheetComponent = data.sheetComponent;
        this.currencyUnit = getCurrencySignByID(this.sheetComponent.getSheet().currencyID);//TODO getFromSheet?
        this.distanceUnit = getDistanceUnitByID(this.sheetComponent.getSheet().distanceID);
    }

    withMaintenance() {

        if (!this.parent.exportPermision) {
            this.messagingService.showMessage("You don't have permission to export sheets to Excel!");
            return;
        }

        this.spinner.show();

        let additionalObj = {
          eventKey: "exportExcelWithMaintenance"
        }
        let endpoint = '/cce/transactions/exportExcelWithMaintenance';
        this.mixPanelServ.logData(additionalObj, endpoint);

        const sheet = lodash.cloneDeep(this.sheetComponent.getSheet());
        sheet.dataMaintenance.servicePlan.children[0].values = this.servicePlanPriceConversion(sheet.dataMaintenance.servicePlan.children[0].values);
        sheet.dataMaintenance.additionalTasks.children[0].values = this.additionalTaskPriceConversion(sheet.dataMaintenance.additionalTasks.children[0].values);
        sheet.dataMaintenance.wearParts.children[0].values = this.wearPartPriceConversion(sheet.dataMaintenance.wearParts.children[0].values);

        this.http.post(environment.exportExelWithMaintenance, {
            "colIds": sheet.columnIds,
            "dataTCO": sheet.dataTCO,
            "equipment": sheet.dataEquipment,
            "maintenance": sheet.dataMaintenance,
            "benchmarkColumnId": sheet.benchmarkColumnId,
            "currencyUnit": this.currencyUnit,
            "distanceUnit": this.distanceUnit,
            "viewingMode": sheet.viewingMode
        }, {
            observe: 'response',
            responseType: 'blob'
        }).subscribe(response => {
            this.downloadFile(response.body, "cce-report.xlsx");
        }, error => {
            this.spinner.hide();
        });

    }

    servicePlanPriceConversion(servicePlanInternal) {
        for (const property in servicePlanInternal) {
            for (let i = 0; i < servicePlanInternal[property].length; ++i) {
                let serviceParts = servicePlanInternal[property][i].serviceParts;
                for (let j = 0; j < serviceParts.length; ++j) {
                    let servicePart = serviceParts[j].partData;
                    for  (let partDataIndex = 0; partDataIndex < servicePart.length; ++partDataIndex) {
                        servicePart[partDataIndex].price =
                            calcPriceByRate(servicePart[partDataIndex].price, this.sheetComponent.getSheet().exchangeRates.get(parseInt(property)));
                    }
                }
            }
        }
        return servicePlanInternal;
    }

    additionalTaskPriceConversion(additionalTaskInternal) {
        for (const property in additionalTaskInternal) {
            for (let i = 0; i < additionalTaskInternal[property].length; ++i) {
                let parts = additionalTaskInternal[property][i].parts;
                for (let j = 0; j < parts.length; ++j) {
                    let part = parts[j].partData;
                    for  (let partDataIndex = 0; partDataIndex < part.length; ++partDataIndex) {
                        part[partDataIndex].price =
                            calcPriceByRate(part[partDataIndex].price, this.sheetComponent.getSheet().exchangeRates.get(parseInt(property)));
                    }
                }
            }
        }
        return additionalTaskInternal;
    }

    wearPartPriceConversion(wearPartInternal) {
        for (const property in wearPartInternal) {
          for (let i = 0; i < wearPartInternal[property].length; ++i) {
            let wp = wearPartInternal[property][i];
            wp.price =
                calcPriceByRate(wp.price, this.sheetComponent.getSheet().exchangeRates.get(parseInt(property)));
          }
        }
        return wearPartInternal;
    }

    downloadFile(data, fileName) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        // a.style = "display: none";
        a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.spinner.hide();
        this.parent.closeExcelDialog();
    }

    withoutMaintenance() {

        if (!this.parent.exportPermision) {
            this.messagingService.showMessage("You don't have permission to export sheets to Excel!");
            return;
        }

        this.spinner.show();

        let additionalObj = {
          eventKey: "exportExcelWithoutMaintenance"
        }
        let endpoint = '/cce/transactions/exportExcelWithoutMaintenance';
        this.mixPanelServ.logData(additionalObj, endpoint);

        const sheet = this.sheetComponent.getSheet();
        this.http.post(environment.exportExelWithoutMaintenance, {
            "colIds": sheet.columnIds, "dataTCO": sheet.dataTCO,
            "equipment": sheet.dataEquipment, "maintenance": sheet.dataMaintenance,
            "benchmarkColumnId": sheet.benchmarkColumnId,
            "currencyUnit": this.currencyUnit,
            "distanceUnit": this.distanceUnit,
            "viewingMode": sheet.viewingMode
        }, {
            observe: 'response',
            responseType: 'blob'
        }).subscribe(response => {
            this.downloadFile(response.body, "cce-report.xlsx");
        }, error => {
            this.spinner.hide();
        });

    }
}


