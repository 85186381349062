import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular/lib/interfaces';

@Component({
  selector: 'btn-cell-renderer',
  templateUrl: './btn-cell-renderer.component.html',
  styleUrls: ['./btn-cell-renderer.component.scss']
})
export class BtnCellRenderer implements AgRendererComponent {

  constructor() {
  }

  refresh(params?: any): boolean {
    return false;
  }

  //this is one InnerRow in ConfiguredParamsComponent
  params: any;

  agInit(params: any): void {
    this.params = params;
    // console.log(params, "brnRender")
  }

  btnRestoreHandler(event: any) {
    // console.log(this.params.data);
    this.params.data.restored = !this.params.data.restored;
    // console.log(event, "btnRestoreHandler", this.params)

    // this.params.api.refreshCells({ rowNodes: [this.params.node], force: true }); // Refresh the row
    // this.params.api.refreshCells();
  }

  ngOnDestroy(): void {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }


}
