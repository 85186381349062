<div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#1d71b8" type="line-scale"></ngx-spinner>
  <div id="param-config">
    <div class="params-scope">

      <div class="cce-row">
        <div class="flat breadcrumb cce-row default-parameters">
          <a [class]="(numberPage > 0)?'clickable-breadcrump':'active'" (click)="goToDefault()">DEFAULT PARAMETERS</a>
          <span class="tabs-divider"> | </span>
          <a [class]="(numberPage == 0)?'clickable-breadcrump':'active'" (click)="goToConfigured()">CONFIGURED
            PARAMETERS</a>
        </div>
        <div *ngIf="(numberPage == 0)" class="cce-row buttons-header">
          <div class="functional-link open-change" (click)="openChangePass()">Change Password</div>
          <div class="functional-link" (click)="resetThisLevel()">Restore Parameters</div>
          <div class="functional-link" (click)="openScenarioSelection()" id="default-functional-link">Default Scenarios
          </div>
        </div>
      </div>

      <div class="cce-row">
        <app-param-scope *ngIf="(numberPage == 0)" #theScope [paramConfig]="this"
          style="width: 100%;"></app-param-scope>
      </div>
    </div>

    <mat-card class="params-form">
      <mat-card-content>
        <app-configured-params *ngIf="(numberPage > 0)"></app-configured-params>

        <app-param-form *ngIf="(numberPage == 0)" #theParameters [paramConfig]="this"></app-param-form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
