import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountService } from '@app/services/account.service';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { environment } from '@environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  srclink: any;
  showLoadingTimeout = false;

  constructor(private accountService: AccountService, private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService, private messageService: HandleUiMessagesService) { }

  ngOnInit(): void {
    this.srclink = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.aboutLink
    );

    window.addEventListener('message', this.sendInstructions);

  }

  ngOnDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('message', this.sendInstructions);
  }


  sendInstructions = (event: MessageEvent): void => {
    if (!environment.aboutLink.startsWith(event.origin)) {
      return;
    }

    // console.log(event.data, "event.data")

    const data = JSON.parse(event.data);
    switch (data?.messageType) {
      case "createSalesforceTicketSuccess":
        this.spinner.hide();
        this.messageService.showMessage("Your question has been submitted successfully.");
        this.showLoadingTimeout = false;
        // console.log("Stop loading, show success message");
        break;

      case "showLoading":
        this.spinner.show();
        this.showLoadingTimeout = true;
        setTimeout(() => {
          if (this.showLoadingTimeout) {
            this.spinner.hide();
          }
        }, 10000);
        // console.log("Show loading");
        break;

      case "readyForConfig":
        this.spinner.show();
        this.accountService.getAboutInfo().subscribe(response => {
          this.spinner.hide();

          let message = response;

          message['messageType'] = "setConfig";
          message['orgId'] = environment.orgId;
          message['endpoint'] = environment.salesforceEndpoint;

          const iframe = document.querySelector("iframe");
          iframe.contentWindow.postMessage(JSON.stringify(message), "*");
          // console.log(message, "message");

        }, error => {
          this.spinner.hide();
          if (typeof error.error === "object") {
            this.messageService.showMessage("Error: " + error.error.message);
          } else {
            this.messageService.showMessage("Error: " + error.error);
          }
        });
        break;

      default:
        console.warn("unsupported message type: " + data?.messageType);
    }

  }

}
