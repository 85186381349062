
import { Component, ViewChild, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParamState } from '../param-form/param-form.component';
import { ScopeService } from '@app/services/param-scope.service';
import { BtnCellRenderer } from '../btn-cell-renderer/btn-cell-renderer.component';
import { PrDispatchComponent } from '../pr-dispatch/pr-dispatch.component';
import { Constants } from '@app/helpers/Constants';
import { UserPreferencesService } from '@app/services/user-preferences.service';
import { getCurrencyIDByAbbr, getCurrencySignByID } from '@app/helpers/RemapUnitsUtils';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { roundValue } from '@app/helpers/utils.js';
import { MixPanelService } from '@app/services/mix-panel.service';


class InnerRow {
  param_id: string;
  make: string;
  market: string;
  model: string;
  fuel: string;
  section: string;
  field: string;
  fieldName: string;
  value: number;
  restored: boolean = false;
  origValue: number;
  modified: number = 0;

  constructor() {

  }

  createFromParmObj(param, field, currencyID) {
    // console.log(param, field, "param, field")
    this.param_id = param._id;
    this.market = param.market;
    this.make = param.make;
    this.model = param.model;
    this.fuel = param.fuel;
    this.section = ConfiguredParamsComponent.fieldMap[field].section;
    this.field = field;
    this.fieldName = ConfiguredParamsComponent.fieldMap[field].name + " (" +
      (Constants.CURRENCY_PLACE_HOLDERS.includes(field) ? getCurrencySignByID(currencyID) : "%") + ")";
    this.value = param[field];
    this.restored = this.restored;
    this.origValue = this.value;
    return this;
  }

  getFiltersPath(): string {
    // Create an array of the properties to be combined
    const parts = [this.market, this.make, this.model, this.fuel];
    // Filter out null or undefined values and join them with a '/'
    return parts.filter(part => part !== null && part !== undefined).join('/') || 'default';
  }

}


@Component({
  selector: 'app-configured-params',
  templateUrl: './configured-params.component.html',
  styleUrls: ['./configured-params.component.scss']
})
export class ConfiguredParamsComponent implements OnInit {

  NO_MODIFIED_CELL = Constants.NO_MODIFIED_CELL;
  MODIFIED_CELL = Constants.MODIFIED_CELL;
  ERROR_MODIFIED_CELL = Constants.ERROR_MODIFIED_CELL;

  public static fieldMap = {
    netPriceReduction: { section: "Net Price", name: "Net price reduction" },
    vehicleDiscount: { section: "Net Price", name: "Vehicle discount" },
    optionalEquipmentPrice: { section: "Net Price", name: "Optional equipment price" },
    optionalEquipmentPCT: { section: "Net Price", name: "Optional equipment" },
    interestRatePA: { section: "Financing", name: "Interest rate, p.a." },
    downPayment: { section: "Financing", name: "Down payment" },
    insuranceRatePA: { section: "Insurance", name: "Insurance rate, p.a." },
    petrol: { section: "Fuel", name: "Petrol" },
    diesel: { section: "Fuel", name: "Diesel" },
    ethanol: { section: "Fuel", name: "Ethanol" },
    liquefiedPetrоleumGas: { section: "Fuel", name: "Liquefied petroleum gas" },
    compressedNaturalGas: { section: "Fuel", name: "Compressed natural gas" },
    electricityPerkWh: { section: "Fuel", name: "Electricity, per kWh" },
    evSystemEfficiency: { section: "Fuel", name: "EV system efficiency " },
    adBlue: { section: "Fuel", name: "AdBlue" },
    servicePartsDiscount: { section: "Service", name: "Service parts discount" },
    serviceLabourDiscount: { section: "Service", name: "Service labour discount" },
    unplannedService: { section: "Service", name: "Unplanned service, p.a." },
    wearPartsDiscount: { section: "Wear", name: "Wear parts discount" },
    overhaulLabourDisc: { section: "Wear", name: "Overhaul labour discount" },
    unplannedWear: { section: "Wear", name: "Unplanned wear, p.a." },
    tyreDiscount: { section: "Tyre", name: "Tyre discount" },
    vatRate: { section: "Taxation", name: "VAT Rate" },
    acquisitionTaxReduction: { section: "Taxation", name: "Acquisition tax reduction" },
    utilisationTaxReduction: { section: "Taxation", name: "Utilisation tax reduction, p.a." },
  };

  constructor(private paramScopeService: ScopeService, private spinner: NgxSpinnerService,
    private messageService: HandleUiMessagesService, private userPreferencesService: UserPreferencesService,
    private mixPanelServ: MixPanelService) {
  }

  @ViewChild('theGrid') theGrid;
  currencyID = 0;
  frameworkComponents;
  gridData: InnerRow[] = [];


  ngOnInit(): void {
    this.frameworkComponents = { btnCellRenderer: BtnCellRenderer };
    //Must to clean if there are change without save in other tab in currency
    this.userPreferencesService.loadUserPreferences().subscribe(prefs => {
      let newCurrCode = prefs.currency;
      this.currencyID = getCurrencyIDByAbbr(newCurrCode);
      this.loadData();
    });
  }


  loadData() {
    this.gridData = [];

    this.spinner.show();
    this.paramScopeService.loadConfiguredParams().subscribe((data: ParamState[]) => {
      // data is ordered!
      if (data && data.length > 0) {
        data.forEach(param => {
          Object.keys(param).forEach(key => {
            if (param[key] != null && key != "_id" && key != "model" && key != "market" && key != "fuel" && key != "make" && key != "username") {
              param[key] = roundValue(param[key], key);
              this.gridData.push(new InnerRow().createFromParmObj(param, key, this.currencyID));
            }
          });
        });
      }
      this.spinner.hide();

      this.sortGridData();
      this.theGrid.api.setRowData(this.gridData);
    },
      error => {
        this.spinner.hide();
        this.messageService.showMessage("Failed to load parameters!");
      });

  }

  sortGridData() {
    this.gridData.sort((a, b) => {
      if (a.market === null || a.market === undefined) return 1;
      if (b.market === null || b.market === undefined) return -1;
      const countryComp = a.market.localeCompare(b.market);
      if (countryComp !== 0) {
        return countryComp;
      }

      if (a.make === null || a.make === undefined) return 1;
      if (b.make === null || b.make === undefined) return -1;
      const makecountryComp = a.make.localeCompare(b.make);
      if (makecountryComp !== 0) {
        return makecountryComp;
      }

      if (a.model === null || a.model === undefined) return 1;
      if (b.model === null || b.model === undefined) return -1;
      const modelComp = a.model.localeCompare(b.model);
      if (modelComp !== 0) {
        return modelComp;
      }

      if (a.fuel === null || a.fuel === undefined) return 1;
      if (b.fuel === null || b.fuel === undefined) return -1;
      return a.fuel.localeCompare(b.fuel);
    });
  }

  returnAllForEmpty(params) {
    // Check if the cell value is an empty string or null
    return params.value === "" || params.value === null ? "ALL" : params.value;
  }


  public columnDefs = [
    {
      headerName: 'COUNTRY', field: 'market', width: 100
    },
    {
      headerName: 'MAKE', field: 'make', cellRenderer: (params) => {
        return this.returnAllForEmpty(params);
      }
    },
    {
      headerName: 'MODEL', cellRenderer: (params) => {
        return this.returnAllForEmpty(params);
      }, field: 'model'
    },
    {
      headerName: 'FUEL', cellRenderer: (params) => {
        return this.returnAllForEmpty(params);
      }, field: 'fuel'
    },
    { headerName: 'SECTION', field: 'section' },
    { headerName: 'FIELD', field: 'fieldName', width: 270 },
    {
      headerName: 'VALUE', field: 'value', editable: true, cellRendererFramework: PrDispatchComponent, cellRendererParams: {
        currencyID: this.currencyID
      }
    },
    { headerName: 'ACTION', field: 'param_id', width: 160, cellRenderer: 'btnCellRenderer' },

  ];

  public gridOptionsSpecs: GridOptions = {
    context: this,
    suppressRowClickSelection: true,
    // suppressHorizontalScroll: false,
    defaultColDef: {
      width: 170,
      resizable: true,
      //   // sortable: true,
      //   filter: false
    }

  };

  fillObjectToSend(el) {
    let obj: any = {};
    if (!el.restored) {
      obj[el.field] = roundValue(el.value, el.field);
    } else {
      obj[el.field] = null;
    }
    return obj;
  }

  onApply() {
    this.theGrid.api.stopEditing();
    let mixPanelData = [];

    let invalidData = false;
    let toSend = new Map();
    // console.log(this.gridData, "ApplyLog")
    this.gridData.forEach(el => {
      if (el.modified == this.MODIFIED_CELL || el.restored) {
        let record = toSend.get(el.param_id);

        if (record != null) {
          record[el.field] = el.restored ? null : roundValue(el.value, el.field);
        } else {
          toSend.set(el.param_id, this.fillObjectToSend(el));
        }

        mixPanelData.push({
          path: el.getFiltersPath(),
          currency: getCurrencySignByID(this.currencyID),
          name: el.field,
          value: el.restored ? null : roundValue(el.value, el.field)
        });

      } else if (el.modified == this.ERROR_MODIFIED_CELL) {
        invalidData = true;
      }
    });

    if (invalidData) {
      this.messageService.showMessage("Failed to save Parameters!");
      return;
    }
    if (toSend.size == 0) {
      this.messageService.showMessage("There are no data for save!");
      return;
    }

    // console.log(toSend, "toSend")
    let requestObj: any[] = [];
    let count = 0;
    toSend.forEach((value, key) => {
      // console.log(key, "keyvalue", value)
      requestObj[count++] = value;
      value.id = key;
    });

    if (requestObj.length == 0) {
      return;
    }

    // console.log(toSend, "toSend", requestObj)
    this.spinner.show();
    this.paramScopeService.saveConfiguredParams(requestObj).subscribe(
      data => {
        this.spinner.hide();
        this.messageService.showMessage(data["message"]);
        this.loadData();
        if (mixPanelData && mixPanelData.length > 0) {
          let additionalObj = {
            eventKey: "changeParameters",
            details: mixPanelData
          }
          let endpoint = '/cce/transactions/changeParameters';
          // console.log(additionalObj, "mixPanelData");
          this.mixPanelServ.logData(additionalObj, endpoint);
        }
      },
      error => {
        this.spinner.hide();
        this.messageService.showMessage("Could not persist all changes! Please review current values!");
        this.loadData();
      });

  }

}


