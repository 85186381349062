import { NewPass } from './../components/change-pass/change-pass.component';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/domain/user';
import { SorcService } from './sorc.service';
import { HandleUiMessagesService } from './handle-ui-messages.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    constructor(
        private router: Router,
        private http: HttpClient,
        private messageService: HandleUiMessagesService,
        private sorcService: SorcService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    public get userName(): String {
        return this.userSubject.value.username;
    }

    public get companyName(): String {
        return this.userSubject.value.companyName;
    }

    public get subjectName(): String {
        return this.userSubject.value.subjectName;
    }

    login(username, password) {
        sessionStorage.removeItem('user');
        this.userSubject.next(null);
        sessionStorage.setItem('basket', 'N/A');
        return this.http.post<User>(environment.authEndpoint, { username, password })
            .pipe(map(user => {
                sessionStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                this.sorcService.loadDefaultBasket();
                return user;
            }));
    }

  resetPass(email): Observable<any> {
    return this.http.post<Object>(environment.resetPassEndpoint, email);
  }

  changePass(data: NewPass): Observable<any> {
    if (data.email) {
      sessionStorage.removeItem('user');
      this.userSubject.next(null);
      sessionStorage.setItem('basket', 'N/A');
    }
    return this.http.post<User>(environment.changePassEndpoint, data).pipe(map(user => {
      sessionStorage.setItem('user', JSON.stringify(user));
      this.userSubject.next(user);
      this.sorcService.loadDefaultBasket();
      return user;
    }));
  }

    logout() {
        sessionStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    hasRole(role): boolean {
        let user = JSON.parse(sessionStorage['user']);
        let token = user['token'];
        let payload = JSON.parse(atob(token.split('.')[1]));
        let roles = payload['roles'];
        return roles.includes(role);
    }

    hasPermission(permission): boolean {
        let user = JSON.parse(sessionStorage['user']);
        let token = user['token'];
        let payload = JSON.parse(atob(token.split('.')[1]));
        let permissions = payload['authorities'];
        return permissions.includes(permission);
    }

    showJobOverlap(): boolean {
        let user: User = JSON.parse(sessionStorage['user']);
        return user.jobOverlap;
    }

    getAboutInfo(): Observable<any> {
      return this.http.get<any>(environment.aboutInfoEndpoint);
    }

}
