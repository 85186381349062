import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { getCurrencySignByID } from '@app/helpers/RemapUnitsUtils';
import { Constants } from '@app/helpers/Constants';

@Component({
  selector: 'app-pr-currency',
  templateUrl: './pr-currency.component.html',
  styleUrls: ['./pr-currency.component.scss']
})
export class PrCurrencyComponent implements OnInit {

  @Input() params: ICellRendererParams;
  NO_MODIFIED_CELL = Constants.NO_MODIFIED_CELL;
  MODIFIED_CELL = Constants.MODIFIED_CELL;
  ERROR_MODIFIED_CELL = Constants.ERROR_MODIFIED_CELL;

  value: string;
  row;
  currencyID;

  constructor() { }

  ngOnInit(): void {
    this.currencyID = this.params.context.currencyID;
    this.row = this.params.data;
    this.value = this.row.value == undefined ? "" : this.row.value;
    // const units = getCurrencySignByID(this.currencyID);
    // console.log(this.params.data.value, this.params.data.origValue, "this.params.data.value !== this.params.data.origValue");
    if (this.row.value != this.row.origValue) {
      this.row.modified = this.MODIFIED_CELL;
      // return;
    } else {
      this.row.modified = this.NO_MODIFIED_CELL;
    }

    if (!this.isValidValue(this.value)) {
      this.row.modified = this.ERROR_MODIFIED_CELL;
    }

    this.value = this.value; //+ units;

    // console.log(" this.row.modified ", this.row.modified)
  }


  isValidValue(value) {
    if (!Number.isNaN(Number(value))) {
      return true;
    }
    return false;
  }

}
