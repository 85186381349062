import { Component, OnInit, Input, AfterViewChecked, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScopeService } from '@app/services/param-scope.service';
import { ParamConfigComponent } from '../param-config/param-config.component';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HandleUiMessagesService } from '@app/services/handle-ui-messages.service';
import { getCurrencyIDByAbbr, getCurrencySignByID } from '@app/helpers/RemapUnitsUtils';
import { MixPanelService } from '@app/services/mix-panel.service';
import { roundValue } from '@app/helpers/utils.js';


@Component({
  selector: 'app-param-form',
  templateUrl: './param-form.component.html',
  styleUrls: ['./param-form.component.scss'],
})

export class ParamFormComponent implements OnInit {
  currency = "€";
  paramForm: FormGroup;
  placeholders = {
    'vehicleDiscount': '',
    'netPriceReduction': '',
    'interestRatePA': '',
    'downPayment': '',
    'petrol': '',
    'diesel': '',
    'ethanol': '',
    'liquefiedPetrоleumGas': '',
    'compressedNaturalGas': '',
    'electricityPerkWh': '',
    'evSystemEfficiency': '',
    'adBlue': '',
    'servicePartsDiscount': '',
    'serviceLabourDiscount': '',
    'unplannedService': '',
    'wearPartsDiscount': '',
    'overhaulLabourDisc': '',
    'unplannedWear': '',
    'tyreDiscount': '',
    'vatRate': '',
    'acquisitionTaxReduction': '',
    'utilisationTaxReduction': '',
    'optionalEquipmentPCT': '',
    'optionalEquipmentPrice': '',
    'insuranceRatePA': '',
  };

  parameters = {};
  currentScope = {};
  isForSave: boolean = false;
  default: string = "default";
  isSelectedScope = false;
  @Input() paramConfig: ParamConfigComponent;
  private nonNegativeFloatValidator = '^[0-9]+[.]?[0-9]*$';
  private floatValidator = '^[\-]?[0-9]+[.]?[0-9]*$';
  private integerValidator = '^[\-]?[0-9]+$';
  private nonNegativeIntegerValidator = '^[0-9]+$';
  semaphor: number = 0;
  public fieldsOldValues: Map<string, any> = new Map();

  constructor(private formBuilder: FormBuilder, private paramScopeService: ScopeService, private userPreferencesService: UserPreferencesService,
    private messageService: HandleUiMessagesService, private spinner: NgxSpinnerService, private mixPanelServ: MixPanelService) { }
  ngOnInit(): void {
    let currencyCode = this.userPreferencesService.getUserPreferencesNow().currency;
    this.currency = getCurrencySignByID(getCurrencyIDByAbbr(currencyCode));
    this.paramForm = this.formBuilder.group({
      vehicleDiscount: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      netPriceReduction: ['', Validators.pattern(this.floatValidator)],
      interestRatePA: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      downPayment: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      petrol: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      diesel: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      ethanol: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      liquefiedPetrоleumGas: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      compressedNaturalGas: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      electricityPerkWh: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      evSystemEfficiency: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      adBlue: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      servicePartsDiscount: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      serviceLabourDiscount: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      unplannedService: ['', Validators.pattern(this.floatValidator)],
      wearPartsDiscount: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      overhaulLabourDisc: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      unplannedWear: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      tyreDiscount: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      vatRate: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
      acquisitionTaxReduction: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      utilisationTaxReduction: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      insuranceRatePA: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      optionalEquipmentPrice: ['', Validators.pattern(this.nonNegativeFloatValidator)],
      optionalEquipmentPCT: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.nonNegativeFloatValidator)
      ])],
    });

    this.paramForm.disable();

    this.paramForm.valueChanges.subscribe(value => {
      let keys = Object.keys(value);
      for (let index in keys) {
        let currentValue = value[keys[index]];
        if (currentValue === "") {
          this.paramForm.controls[keys[index]].reset();
        }
      }
    });
  }

  setCurrencySign(sign) {
    this.currency = sign;
  }

  loadDefaultParameters(withCurrentLevel: boolean) {
    this.fieldsOldValues.clear();
    let market = this.paramConfig.getMarket();
    let make = this.paramConfig.getMake();
    let model = this.paramConfig.getModel();
    let fuel = this.paramConfig.getFuel();
    this.isSelectedScope = !!market;
    this.parameters = {};
    this.currentScope = {};
    // this.placeholders = {};
    this.paramForm.reset();
    this.paramForm.disable();
    this.paramScopeService.loadParamFormData(market, make, model, fuel).subscribe((data: ParamState[]) => {
      // data is ordered!
      if (market != null) {
        this.paramForm.enable();
      }
      if (data && data.length > 0) {
        data.forEach(pState => {
          let scope = this.getScopeByState(pState);
          Object.keys(pState).forEach(key => {
            pState[key] = roundValue(pState[key], key);
            this.fillPlaceholderWithValue(key, pState[key], scope, withCurrentLevel);
          });
        });
      }
    });
  }


  private getScopeByState(state: ParamState) {
    return this.getScopeLabel(state.username, state.market, state.make, state.model, state.fuel);
  }
  private getCurrentScope() {
    return this.getScopeLabel("dummyNotNull", this.paramConfig.getMarket(), this.paramConfig.getMake(), this.paramConfig.getModel(), this.paramConfig.getFuel());
  }

  private getScopeLabel(username: string, market: string, make: string, model: string, fuel: string) {
    if (username == null) {
      return this.default
    } else if (fuel != null) {
      return fuel;
    } else if (model != null) {
      return model;
    } else if (make != null) {
      return make;
    } else if (make != null) {
      return make;
    } else {
      return market;
    }
  }

  fillPlaceholderWithValue(key, value, scope, withCurrentLevel) {
    if (value !== undefined && value !== null && scope !== this.getCurrentScope()) {
      this.placeholders[key] = value + " (" + scope + ")";
      this.parameters[key] = {};
      this.parameters[key]['value'] = value;
      this.parameters[key]['scope'] = scope;
      this.fieldsOldValues.set(key, null);
    }
    if (withCurrentLevel) {
      if (scope === this.getCurrentScope() && value !== undefined && value !== null) {
        if (this.paramForm.controls[key]) {
          this.placeholders[key] = null;
          this.parameters[key] = {};
          this.parameters[key]['value'] = value;
          this.parameters[key]['scope'] = scope;
          this.paramForm.controls[key].setValue(value);
          this.currentScope[key] = true;
          this.fieldsOldValues.set(key, value);
        }
      }
    } else {
      if (scope === this.getCurrentScope() && value) {
        if (this.paramForm.controls[key]) {
          // this.placeholders[key] = null;
          // this.parameters[key] = {};
          // this.parameters[key]['value'] = null;
          // this.parameters[key]['scope'] = scope;
          this.paramForm.controls[key].setValue(null);
          this.currentScope[key] = false;
          this.fieldsOldValues.set(key, value);
        }
      }
    }
  }

  prepareDataForSave(): ParamState {
    let res = new ParamState();
    let controls = this.paramForm.controls;
    res.market = this.paramConfig.getMarket();
    res.make = this.paramConfig.getMake();
    res.model = this.paramConfig.getModel();
    res.fuel = this.paramConfig.getFuel();
    let lastScope = this.getCurrentScope();
    let keys = Object.keys(controls);
    Object.keys(controls).forEach(field => {
      if (controls[field].value === null) {
        // overwrite null value
        res[field] = null;
        this.isForSave = true;
      } else {
        let value: number;
        res[field] = roundValue(controls[field].value, field);
        this.isForSave = true;
      }
    });
    return res;
  }

  getFullScopePath() {
    let market = this.paramConfig.getMarket();
    let make = this.paramConfig.getMake();
    let model = this.paramConfig.getModel();
    let fuel = this.paramConfig.getFuel();

    if (null == market || "ALL" == market) {
      return "";
    }
    if (null == make || "ALL" == make) {
      return market;
    }

    if (null == model || "ALL" == model) {
      return market + "/" + make;
    }
    if (null == fuel || "ALL" == fuel) {
      return market + "/" + make + "/" + model;
    }

    return market + "/" + make + "/" + model + "/" + fuel;
  }

  getChangedParams() {
    let retval = [];

    let controls = this.paramForm.controls;
    if (this.fieldsOldValues.size > 0) {
      Object.keys(controls).forEach(field => {
        if (
          controls[field].value != this.fieldsOldValues.get(field) &&
          (!!controls[field].value || !!this.fieldsOldValues.get(field))) {
          retval.push({
            path: this.getFullScopePath(),
            currency: this.currency,
            name: field,
            value: roundValue(controls[field].value, field)
          });
        }
      });
    }
    return retval;
  }

  onApply() {
    let changed = this.getChangedParams();
    let params = this.prepareDataForSave();

    if (!this.paramForm.valid && params.market !== null) {
      this.messageService.showMessage("Failed to save Parameters!");
      return;
    }

    let requestObj: any = {};
    if (!this.paramForm.valid || params.market === null || !this.isForSave) {
      // saving only preferences!
      requestObj.saveParam = false;
    } else {
      // save all;
      requestObj.params = params;
      requestObj.saveParam = true;
    }

    requestObj.pref = this.userPreferencesService.getUserPreferencesNow();

    this.spinner.show();
    this.paramScopeService.saveParamWithPreferences(requestObj).subscribe(
      data => {
        this.spinner.hide();
        this.loadDefaultParameters(true);
        this.paramConfig.setPreferences(data);
        this.currency = getCurrencySignByID(getCurrencyIDByAbbr(data.currency));
        if (changed && changed.length > 0) {
          let additionalObj = {
            eventKey: "changeParameters",
            details: changed
          }
          let endpoint = '/cce/transactions/changeParameters';
          this.mixPanelServ.logData(additionalObj, endpoint);
        }
        this.messageService.showMessage("User preferences saved. Changes will apply to newly opened sheets only; existing sheets will not be affected!");
      },
      error => {
        this.spinner.hide();
        this.messageService.showMessage("Failed to save user preferences!");
      });
  }
}

export class ParamState {
  username?: string;
  market?: string;
  make?: string;
  model?: string;
  fuel?: string;

  vehicleDiscount?: number;
  netPriceReduction?: number;
  interestRatePA?: number;
  downPayment?: number;
  petrol?: number;
  diesel?: number;
  ethanol?: number;
  liquefiedPetrоleumGas?: number;
  compressedNaturalGas?: number;
  electricityPerkWh?: number;
  evSystemEfficiency?: number;
  adBlue?: number;
  servicePartsDiscount?: number;
  serviceLabourDiscount?: number;
  unplannedService?: number;
  wearPartsDiscount?: number;
  overhaulLabourDisc?: number;
  unplannedWear?: number;
  tyreDiscount?: number;
  vatRate?: number;
  acquisitionTaxReduction?: number;
  utilisationTaxReduction?: number;
  insuranceRatePA?: number;
  optionalEquipmentPrice?: number;
  optionalEquipmentPCT?: number;
}

export class PrefState {
  username?: string;
  language?: string;
  currency?: string;
}

