const backendRoot = "http://192.168.201.64:6060";
const auditRoot = "http://txn.qa.autovistacloud.net.int/transaction";
const aboutLinkDef = "https://cce-content-testdev.s3.eu-west-1.amazonaws.com/CCE_JSON/view.html#contentContact";
const orgId = "00D000000CrYc";
const salesforceEndpoint = "https://autovistagroupsf--codlakeqa.my.sales....?encoding=UTF-8";

export const metadata = {};
export const filters = {};
export const taxesInformation = {};
export const environment = {
  backendRoot: backendRoot,
  production: true,
  auditRoot : auditRoot,
  aboutLink : aboutLinkDef,
  taxesInformation : taxesInformation,
  orgId: orgId,
  salesforceEndpoint: salesforceEndpoint,
  metadataEndpoint: backendRoot + "/calc/metadata",
  authEndpoint: backendRoot + "/auth/login",
  resetPassEndpoint: backendRoot + "/auth/resetPass",
  changePassEndpoint: backendRoot + "/auth/changePass",
  uploadEnpoint:backendRoot + "/upload/csv",
  carSpecEndpoint: backendRoot + "/sorc/basket",
  paramScopeEndpoint: backendRoot + "/amf/paramScope",
  paramFormEndpoint: backendRoot + "/amf/paramForm",
  saveParamWithPreferencesEndpoint: backendRoot + "/amf/saveParamWithPreferences",
  configuredParamsEndpoint: backendRoot + "/amf/configuredParams",
  saveConfiguredParamsEndpoint: backendRoot + "/amf/saveConfiguredParams",
  specsScopeEndpoint: backendRoot + "/amf/specsScope",
  exportExelWithMaintenance: backendRoot + "/export/withMaintenance",
  exportExelWithoutMaintenance: backendRoot + "/export/withoutMaintenance",
  exportCSV: backendRoot + "/export/csv",
  addVehicleEndpoint: backendRoot + "/vehicle/addVehicle",
  callCalcEndpoint: backendRoot + "/vehicle/callCalc",
  addNewSheetEndpoint: backendRoot + "/vehicle/addNewSheet",
  equipmentEndpoint: backendRoot + "/calc/equipment",
  loadCalcModelEndpoint: backendRoot + "/calc/loadModel",
  saveCalcModelEndpoint: backendRoot + "/calc/saveModel",
  sorkVehiclesEndpoint: backendRoot + "/sorc/vehicles",
  loadUserPreferencesEndpoint: backendRoot + "/amf/preferences",
  saveUserPreferencesEndpoint: backendRoot + "/amf/savePreferences",
  utilsProxyEndpoint: backendRoot + "/util/proxy",
  aboutInfoEndpoint: backendRoot + "/auth/aboutInfo",
  getCceFilesEndpoint: backendRoot + "/cce/getCceFiles",
  deleteCceFiles: backendRoot + "/cce/deleteCceFiles",
  getSheetsFromCceFilesById: backendRoot + "/cce/getSheetsFromCceFilesById",
  saveCceFileEndpoint: backendRoot + "/cce/saveCceFile",
  updateCceFileMetaEndpoint: backendRoot + "/cce/updateCceFileMeta",
  getCceFromDeviceEndpoint: backendRoot + "/cce/openLocal",
  retrieveGroup : backendRoot + "/admin/getGroupUsers",
  createUser : backendRoot + "/admin/createUser",
  getUser : backendRoot + "/admin/getUser",
  uploadUserFile : backendRoot + "/admin/uploadUserFile",
  updateUser : backendRoot + "/admin/updateUser",
  deleteUsers : backendRoot + "/admin/deleteUsers",
  getOrganisation : backendRoot + "/admin/getOrganisation",
  saveOrganisation : backendRoot + "/admin/saveOrganisation",
  esacosEndpoint: backendRoot + "/sorc/esacos",
  heatmapColorsEndpoint: backendRoot + "/heatmap/getColors",
  addScenario:  backendRoot + "/vehicle/addScenario",
  imgEndpoint: backendRoot + "/sorc/images",
  updateEndpoint: backendRoot + "/update/trigger",
  filterEndpoint: backendRoot + "/specs/filter",
  combosEndpoint: backendRoot + "/specs/combos",
  defaultBasketEndpoint: backendRoot + "/specs/basket",
  dataFiltersEndpoint: backendRoot + "/calc/filters"
};
