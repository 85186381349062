<mat-card class="grid">
  <mat-card-content>
    <div class="cce-col">
      <!-- some padding 20, 30 bread, 160 header, 45 custom filter, 155 filters, 45 showing-->
      <ag-grid-angular #theGrid style="height: 570px;" class="ag-theme-balham" [gridOptions]="gridOptionsSpecs"
        [columnDefs]="columnDefs" [rowData]="gridData" [frameworkComponents]="frameworkComponents">
      </ag-grid-angular>

    </div>
    <div class="button_container">
      <button class="button_apply" type="submit" (click)="onApply()" mat-button>APPLY</button>
      <button class="button_close" type="submit" mat-dialog-close mat-button>CLOSE</button>
    </div>
  </mat-card-content>
</mat-card>
